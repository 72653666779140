import React from "react";
import { Link } from "gatsby";

const Pagination = ({basePath, currentPage, numPages}) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = (currentPage - 1) === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const prev = prevPage === '' ? basePath : `${basePath}/${prevPage}`
  const next = `${basePath}/${nextPage}`

  return (
    <>
      {numPages > 1 && (
        <nav className="pagination is-centered" role="navigation"
             aria-label="pagination">
          <Link
              className={`pagination-previous button is-primary is-rounded${isFirst ? ` is-invisible` : ``}`}
              to={prev}
              rel="prev"
          >
            ← Page précédente
          </Link>
          <div className="pagination-list">
            {currentPage} / {numPages}
          </div>
          <Link
              className={`pagination-next button is-primary is-rounded${isLast ? ` is-invisible` : ``}`}
              to={next}
              rel="next"
          >
            Page suivante →
          </Link>
        </nav>
      )}
    </>
  )
}

export default Pagination
