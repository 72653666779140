import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import Small16by9 from "../Images/Small16by9";
import {cssCleanClass} from "../../Utils/functions";
import Truncate from "../Truncate";

const ArticleListItem = ({content, hideTags}) => (
  <div className="card article-list-item">
    <div className="card-image">
      <Link to={content.path.alias}>
        {content.relationships.image ? (
          <GatsbyImage
            image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
            alt={content.title}
            className="w-full"
          />
        ) : (
          <Small16by9 alt={content.title}/>
        )}
      </Link>
    </div>
    <div className="card-content">
      {!hideTags && (
        <span className={`tag is-rounded theme theme-${cssCleanClass(content.relationships.tags.name)}`}>
          <Link to={`/actualites${content.relationships.tags.path.alias}`}>{content.relationships.tags.name}</Link>
        </span>
      )}
      <div className="title is-3">
        <Link to={content.path.alias}>{content.title}</Link>
      </div>
      <Truncate
        className="content"
        limit={120}
        html={(
          content.body.summary.length > 0
            ? content.body.summary
            : content.body.value
        )}
      />
      <div className="view-node">
        <Link to={content.path.alias} className="button is-icon-only is-primary is-rounded">
        <span className="icon icon-medium">
          <i className="li li-plus"></i>
        </span>
        <span className="icon-explain">En savoir plus</span>
        </Link>
      </div>
    </div>
  </div>
)

ArticleListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default ArticleListItem;
