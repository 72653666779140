import React from "react";
import {Breadcrumb} from "gatsby-plugin-breadcrumb";
import PropTypes from "prop-types";

/**
 * TODO: Get all created pages and match the crumbs to it to find the matching crumbLabel
 * TODO: Remove gatsby-plugin-breadcrumb and rendre the breadcrumb manually
 *
 * @param crumbs
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CustomBreadcrumb = ({crumbs, ...props}) => {
    const lastCrumb = crumbs[crumbs.length - 1]

    const improveCrumbsLabel = (crumbLabel) => {
        crumbLabel = crumbLabel.replace(/-/g, ' ')
        crumbLabel = crumbLabel.charAt(0).toUpperCase() + crumbLabel.slice(1)
        return crumbLabel
    }

    crumbs.forEach(({crumbLabel}, index) => {
        crumbs[index].crumbLabel = improveCrumbsLabel(crumbLabel)
    })

    return (
        <>
            <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" &rsaquo; "
                disableLinks={[lastCrumb.pathname]}
                {...props}
            />
        </>
    )
}

CustomBreadcrumb.propTypes = {
    crumbs: PropTypes.array.isRequired
}

export default CustomBreadcrumb
